const ignore = [
  '"status":401',
  '"status":403',
  'This is not a real error!',
  'Unable to find `next-intl` locale',
  'Password reset required for the user',
  'FS is not defined',
  "Can't find variable: FS",
  'Vercel Web Analytics',
  'No authenticated user was found',
  'Could not find operation with id',
  'No router instance found',
];

const serverName = process.env.VERCEL_URL || process.env.NEXT_PUBLIC_VERCEL_URL;

/** @type {import('@sentry/nextjs').EdgeOptions} */
export const sentryCommonOptions = {
  dsn: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  serverName: process.env.VERCEL_URL || process.env.NEXT_PUBLIC_VERCEL_URL,
  environment: process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV || serverName || 'unknown',
  release: process.env.VERCEL_GIT_COMMIT_SHA || process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || undefined,
  tracesSampleRate: 1,
  ignoreErrors: ignore,
  ignoreTransactions: ignore,
};
